import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import { VerificationsService } from '../verifications.service';
import { ProfileService } from '../../profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusLabelComponent } from '../shared-model/status-label/status-label.component';
import { ViewLinkComponent } from '../shared-model/view-link/view-link.component';

import { AlertService } from 'src/app/shared/_alert';
import * as moment from 'moment';
import { EditLinkComponent } from '../shared-model/edit-link/edit-link.component';
import { BooleanLabelComponent } from '../shared-model/boolean-label/boolean-label.component';
import { ExportService } from 'src/app/reports/export.service';
import { RequestDTO } from 'src/app/reports/requestDTO.model';

@Component({
  selector: 'app-verifications-list',
  templateUrl: './verifications-list.component.html',
  styleUrls: ['./verifications-list.component.scss']
})
export class VerificationsListComponent implements OnInit {

  //https://akveo.github.io/ng2-smart-table/#/examples/using-filters

  source: LocalDataSource = new LocalDataSource();


  pageOffset = 0;
  dataLimit = 15;
  tenantList = [];
  tenantID = 0;
  userType = '';
  isSuperAdmin = false;
  isTenantAdmin = false;
  disablePrevButton = true;
  disableNxtButton = false;
  searchValue = '';
  searchMode = false;
  dataSize = 0;
  fromDate = '';
  toDate = '';
  maxDate = new Date();
  status: string;
  isValidDate: any;
  objDetail: any[];
  userdata: any;
  verificationDetailsReport: any[];
  date: string;
  request: RequestDTO = new RequestDTO();

  statusList: any = { 'All': 'all', 'Pending': 'in_progress', 'Started': 'started', 'Success': 'verified', 'Failed': 'failed', 'Error': 'error', 'Expired': 'timed_out' };

  constructor(
    private verificationsService: VerificationsService,
    private profileService: ProfileService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private location: Location,
    public alertService: AlertService,
    private exportService: ExportService    
  ) {

  }

  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      columnTitle: 'Export',
      width: '15%',
      editUrl: true,
      custom: [
        { name: 'exportPDF', title: '<span><i class="fa fa-file-pdf-o" style="font-size:48px;color:red" title="PDF" style="font-size:20px;"></i></span></br>' },
      ],
    },
    pager: {
      display: true,
      perPage: 15
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      identifier: {
        title: 'Transactions ID',
        type: 'String',
        // width: '25%',
        filter: false
      },
      name: {
        title: 'Person Name',
        type: 'String',
        // width: '15%',
        filter: false
      },
      mobile: {
        title: 'Mobile/Device ID',
        type: 'String',
        // width: '10%',
        filter: false
      },
      status: {
        title: 'Status',
        type: 'custom',
        renderComponent: StatusLabelComponent,
        // width: '10%',
        class: 'text-align: center',
        filter: false
      },
      riskFactor: {
        title: 'Risk Score',
        type: 'String',
        // width: '5%',
        filter: false
      },
      requestedTime: {
        title: 'Date',
        type: 'String',
        // width: '20%',
        filter: false
      },
      viewUrl: {
        title: 'View',
        type: 'custom',
        renderComponent: ViewLinkComponent,
        // width: '5%',
        class: 'text-align: center',
        filter: false,
        name: 'View'
      },
      editUrl: {
        title: 'Edit',
        type: 'custom',
        renderComponent: EditLinkComponent,
        // width: '5%',
        class: 'text-align: center',
        filter: false,
        name: 'Edit'
      }
    },
  };

  tableSettingsSuperAdmin = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      columnTitle: 'Export',
      width: '15%',
      editUrl: true,
      custom: [
        { name: 'exportPDF', title: '<span><i class="fa fa-file-pdf-o" style="font-size:48px;color:red" title="PDF" style="font-size:20px;"></i></span></br>' },
      ],
    },
    pager: {
      display: true,
      perPage: 15
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      identifier: {
        title: 'Transactions ID',
        type: 'String',
        // width: '25%',
        filter: false
      },
      name: {
        title: 'Person Name',
        type: 'String',
        // width: '15%',
        filter: false
      },
      mobile: {
        title: 'Mobile/Device ID',
        type: 'String',
        // width: '10%',
        filter: false
      },
      status: {
        title: 'Status',
        type: 'custom',
        renderComponent: StatusLabelComponent,
        // width: '10%',
        class: 'text-align: center',
        filter: false
      },
      riskFactor: {
        title: 'Risk Score',
        type: 'String',
        // width: '5%',
        filter: false
      },
      requestedTime: {
        title: 'Date',
        type: 'String',
        // width: '20%',
        filter: false
      },
      viewUrl: {
        title: 'View',
        type: 'custom',
        renderComponent: ViewLinkComponent,
        // width: '5%',
        class: 'text-align: center',
        filter: false,
        name: 'View'
      }
    },
  };

  ngOnInit(): void {
    const user = this.profileService.getUserDetail();
    this.userType = user.userType; 
    if (this.userType == 'super_admin') {
      this.tableSettings = Object.assign({}, this.tableSettings);
    } else {
      this.tableSettingsSuperAdmin = Object.assign({}, this.tableSettingsSuperAdmin);
    }

    this.status = 'all';
    if (this.userType == 'super_admin') {
      this.getTenantList();
      this.isSuperAdmin = true;
    }

    if (this.userType == 'tenant_admin') { 
      this.isTenantAdmin = true;
    }

    this.disablePrevButton = true;
    this.route.queryParams
      .subscribe(params => {
        if (Object.keys(params).length > 0) {
          this.tenantID = params['tenantID'];
          let pageOffsetStr = params['pageOffset'];
          let pageOffsetNum = Number(pageOffsetStr);
          this.pageOffset = pageOffsetNum;
          this.getVerificationList(this.pageOffset);
          (this.pageOffset > 0) ? this.disablePrevButton = false : this.disablePrevButton = true;
        } else {
          this.getVerificationList(this.pageOffset);
        }
      });
    this.router.navigate(['/verifications'], { replaceUrl: true });
  }

  onCreate(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  onBack() {
    if ((this.fromDate != null || this.fromDate != undefined) && (this.toDate != null || this.toDate != undefined)) {
      if (this.pageOffset > 0 && this.searchMode) {
        if (this.pageOffset === 1) {
          this.disablePrevButton = true;
        }
        this.pageOffset = this.pageOffset - 1;
        this.searchVerificationByDateRange(this.fromDate, this.toDate, this.status, this.pageOffset);
      } else if (this.pageOffset > 0 && !this.searchMode) {
        this.pageOffset = this.pageOffset - 1;
        this.searchVerificationByDateRange(this.fromDate, this.toDate, this.status, this.pageOffset);
        // this.getVerificationList(this.pageOffset);
      } else {
        this.disablePrevButton = true;
      }
    } else {
      if (this.pageOffset > 0 && this.searchMode) {
        if (this.pageOffset === 1) {
          this.disablePrevButton = true;
        }
        this.pageOffset = this.pageOffset - 1;
        this.searchVerification(this.searchValue, this.pageOffset);
      } else if (this.pageOffset > 0 && !this.searchMode) {
        this.pageOffset = this.pageOffset - 1;
        this.getVerificationList(this.pageOffset);
      } else {
        this.disablePrevButton = true;
      }
    }
  }

  onNext() {
    this.pageOffset = this.pageOffset + 1;
    if ((this.fromDate != null || this.fromDate != undefined) && (this.toDate != null || this.toDate != undefined)) {
      this.searchVerificationByDateRange(this.fromDate, this.toDate, this.status, this.pageOffset);
      this.disablePrevButton = false;
    } else {
      if (this.searchMode) {
        this.searchVerification(this.searchValue, this.pageOffset);
      } else {
        this.getVerificationList(this.pageOffset);
      }
      this.disablePrevButton = false;
    }
  }

  payloadMapper(data) {
    let list = [];
    data.forEach(element => {
      const requestedTime = moment(element.requestedTime).format('MMMM Do YYYY, h:mm:ss a');
      let obj = { ...element, requestedTime: requestedTime }
      list.push(obj);
    });
    return list;
  }

  getVerificationList(pageOffset) { 
    this.disableNxtButton = true;
    
    if (this.userType == 'super_admin') {
      if(this.tenantID != 0) {       
        this.verificationsService.getVerificationsByTenantId(this.tenantID, pageOffset, this.dataLimit)
        .subscribe(verifications => {
          this.insertViewLink(verifications, pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.spinner.hide();
        },
        (error) => {
          console.log(error)
        });
      } 
    } else {
      this.verificationsService.getVerificationsPerPage(pageOffset, this.dataLimit)
        .subscribe(verifications => {
          this.insertViewLink(verifications, pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    }
  }

  loadData(tableData) {
    this.dataSize = tableData.length;
    if (tableData.length > 0) {
      this.source.load(tableData);
    } else {
      this.source.load(tableData);
    }
    this.disableNxtButton = false;
  }

  insertViewLink(verifications, pageOffset) {
    verifications.forEach(element => {
      const viewUrl = window.location.origin + this.router.url + '/' + element.identifier + '/' + 'view?tenantID=' + this.tenantID + '&pageOffset=' + pageOffset;
      element.viewUrl = viewUrl;
      if (this.userType == 'super_admin') {
        element.editUrl = '';
      } else {
        if (element.status === "failed" || element.status === "verified" || element.status === "error" || element.status === "timed_out") {
          element.editUrl = '';
        } else {
          const editUrl = window.location.origin + this.router.url + '/' + element.identifier + '/' + 'edit?tenantID=' + this.tenantID + '&pageOffset=' + pageOffset;
          element.editUrl = editUrl;
        }
      }
    });
  }

  onChangeSelect(event) {
    this.pageOffset = 0;
    if (this.userType == 'super_admin') {
      // this.verificationsService.getVerificationsByTenantId(this.tenantID, this.pageOffset, 15)
      // this.verificationsService.getVerificationsByTenantIdAndStatus(this.tenantID, this.status, this.pageOffset, this.dataLimit)
      if(this.tenantID != 0) { 
        this.verificationsService.searchVerificationWithTenantIdDateRange(this.tenantID, this.fromDate, this.toDate, this.status, this.searchValue, this.pageOffset, this.dataLimit).subscribe(verifications => {
          this.insertViewLink(verifications, this.pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.disablePrevButton = true;
        },
        (error) => {
          console.log(error)
        });
      }
    } else {
      this.searchVerificationByDateRange(this.fromDate, this.toDate, this.status, this.pageOffset);
    }

  }

  onSearch() {

    if (this.searchValue.trim() === '') {
      this.alertService.error('Search value is required.', this.options);
    } else if (this.searchValue != '') {
      if (this.userType == 'super_admin') {
        if(this.tenantID == 0){
          this.alertService.error('Tenant Selection is required.', this.options);
          this.isValidDate = false;
        } else {
          this.source.empty();
          this.pageOffset = 0;
          this.searchVerification(this.searchValue, this.pageOffset);
          this.searchMode = true;
        }
      } else{
        this.source.empty();
        this.pageOffset = 0;
        this.searchVerification(this.searchValue, this.pageOffset);
        this.searchMode = true; 
      }
    } else {
      this.searchMode = false;
      this.getVerificationList(0);
    }

  }

  onDateSearch() {
    if (this.validateDates(this.fromDate, this.toDate)) {
      this.source.empty();
      this.pageOffset = 0;
      this.searchVerificationByDateRange(this.fromDate, this.toDate, this.status, this.pageOffset);
      this.searchMode = true;
    }
  }

  validateDates(fromDate, toDate) {
    this.isValidDate = true;
    if ((fromDate == null || toDate == null || fromDate == '' || toDate == '')) {
      this.alertService.error('From Date and To Date are required.', this.options);
      this.isValidDate = false;
    } else if ((fromDate != null && toDate != null) && (toDate) < (fromDate)) {
      this.alertService.error('To Date should be grater then From Date.', this.options);
      this.isValidDate = false;
    }
     
    if (this.userType == 'super_admin') {
      if(this.tenantID == 0){
        this.alertService.error('Tenant Selection is required.', this.options);
        this.isValidDate = false;
      }
    }
    
    return this.isValidDate;
  }

  disableDate() {
    return false;
  }

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  searchVerificationByDateRange(fromDate, toDate, status, pageOffset) {
    this.spinner.show();
    this.source.empty();
    this.disableNxtButton = true;
    if (this.userType == 'super_admin') {
      if(this.tenantID != 0) {
        this.verificationsService.searchVerificationWithTenantIdDateRange(this.tenantID, fromDate, toDate, status, this.searchValue, pageOffset, this.dataLimit).subscribe(verifications => {
          this.insertViewLink(verifications, pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error)
        });
      }
    } else {
      this.verificationsService.searchVerificationWithDateRange(fromDate, toDate, status, this.searchValue, pageOffset, this.dataLimit).subscribe(verifications => {
        this.insertViewLink(verifications, pageOffset);
        const modifiedData = this.payloadMapper(verifications);
        this.loadData(modifiedData);
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          console.log(error)
        });
    }
  }

  searchVerification(query, pageOffset) {
    this.spinner.show();
    this.source.empty();
    this.disableNxtButton = true;
    if (this.userType == 'super_admin') {
      if(this.tenantID != 0) { 
        if ((this.fromDate == null || this.toDate == null || this.fromDate == '' || this.toDate == '')) {
          this.verificationsService.searchVerificationWithTenantIdAndStatus(this.tenantID, this.status, query, pageOffset, this.dataLimit).subscribe(verifications => {
            this.insertViewLink(verifications, pageOffset);
            const modifiedData = this.payloadMapper(verifications);
            this.loadData(modifiedData);
            this.spinner.hide();
          },
            (error) => {
              this.spinner.hide();
              console.log(error)
            });
        } else {
          this.verificationsService.searchVerificationWithTenantIdDateRange(this.tenantID, this.fromDate, this.toDate, this.status, this.searchValue, pageOffset, this.dataLimit).subscribe(verifications => {
            this.insertViewLink(verifications, pageOffset);
            const modifiedData = this.payloadMapper(verifications);
            this.loadData(modifiedData);
            this.spinner.hide();
          },
            (error) => {
              this.spinner.hide();
              console.log(error)
            });
        }
      }
    } else {
      if ((this.fromDate == null || this.toDate == null || this.fromDate == '' || this.toDate == '')) {
        this.verificationsService.searchVerificationWithStatus(query, this.status, pageOffset, 15).subscribe(verifications => {
          this.insertViewLink(verifications, pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
            console.log(error)
          });
      } else {
        this.verificationsService.searchVerificationWithDateRange(this.fromDate, this.toDate, this.status, this.searchValue, pageOffset, this.dataLimit).subscribe(verifications => {
          this.insertViewLink(verifications, pageOffset);
          const modifiedData = this.payloadMapper(verifications);
          this.loadData(modifiedData);
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
            console.log(error)
          });
      }
    }
  }

  getTenantList() {
    this.spinner.show();
    this.verificationsService.getTenantList().subscribe(tenants => {
      for (let tenant of tenants) {
        let row = { name: tenant.tenantName, value: tenant.identifier }
        this.tenantList.push(row);
      }
      (tenants.length > 0) ? this.spinner.hide() : this.spinner.show();
    },
      (error) => {
        this.spinner.hide();
        console.log(error)
      });
  }


  onView(event): void {
    let actionName = `${event.action}`;
    // console.info(actionName);
    if (actionName === 'view') {
      this.router.navigate([event.data.identifier, 'view'], { relativeTo: this.route, queryParams: { tenantID: this.tenantID, pageOffset: this.pageOffset } });
    }
    if (actionName === 'exportPDF') {
      this.downloadFile(event.data.identifier);
    }

    if (actionName === 'edit') {
      this.router.navigate([event.data.identifier, 'edit'], { relativeTo: this.route, queryParams: { tenantID: this.tenantID, pageOffset: this.pageOffset } });
    }

  }

  downloadFile(identifier) {
    this.verificationsService.download(identifier).subscribe(
      response => {
        // response.headers.get('content-disposition');
        let filename = `${identifier}.pdf`;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      }
    )
  }

  onUserRowSelect(event): void {
    // this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  showStaticModal(rowData: any) {
  }

  selectSort(event) {
    this.status = event.target.value;
  }

  downloadCSV() {
    if (this.validateDates(this.fromDate, this.toDate)) {
      const requestObject = {
        "fromDate": this.fromDate,
        "toDate": this.toDate 
      };
 
      this.searchVerificationDetailsByDateRange(requestObject); 
    }
  }

  searchVerificationDetailsByDateRange(requestObject) { 
    this.source.empty(); 
    this.verificationsService.getVerificationDetails(requestObject).subscribe(data => {
      this.objDetail = data; 
      const tempObj = [];
      for (let i = 0; i < this.objDetail.length; i++) {
        var reportObj = {
          "Transaction_Id": this.objDetail[i].identifier, 
          "Requested_Time": this.objDetail[i].requestedTime,
          "Country_Name": this.objDetail[i].country,
          "Document_Type": this.objDetail[i].documentType,         
          "Document_Score": this.objDetail[i].documentScore,
          "Name_Score": this.objDetail[i].nameScore,
          "DOB_Score": this.objDetail[i].dobScore,
          "Face_Match_Score": this.objDetail[i].faceMatches != null && this.objDetail[i].faceMatches.length > 0  ? this.objDetail[i].faceMatches[0] : 0,
          "Liveness":  this.objDetail[i].livenessDTO == null || this.objDetail[i].livenessDTO.live === 'SKIPPED' ? 'N/A' : this.getLiveness(this.objDetail[i].livenessDTO),
          "Risk_Factor": this.objDetail[i].riskFactor, 
          "Status": this.objDetail[i].status,
          "live": this.objDetail[i].liveVideo, 
          "Transaction_Failure_Reason": (this.objDetail[i].failureReason !== null || this.objDetail[i].failureReason !== '') ? this.objDetail[i].failureReason : '-'
          }
        tempObj.push(reportObj);
      }

      this.verificationDetailsReport = tempObj; 
      this.exportToCSV(this.verificationDetailsReport); 

    },(error) => {
      this.spinner.hide();
      console.log(error)
    });
  }

  exportToCSV(result){ 
    this.date = (new Date).getFullYear() + '-' + ((new Date).getMonth() + 1) + '-' + (new Date).getUTCDate() + 'at' + (new Date).getHours() + ':' + (new Date).getMinutes() + ':' + (new Date).getMilliseconds();
    this.exportService.exportAsCSVFile(result,`VERIFICATION_DETAILS_REPORT_FROM_${this.fromDate}_TO_${this.toDate}_ON_${this.date}`);
  }

  getLiveness(liveness) {
    if (liveness && liveness.live) {
      return liveness.live === 'REAL' ? true : false;
    }
    return false;
  }

}
