<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Edit/Add Meta Data</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Enter Metadata Details</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="form">
      <form [formGroup]="metadataGroup" (ngSubmit)="confirm($event)" #formDir="ngForm">
        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="country">Country: <span
                class="required-asterisk ">*</span></label>
            <input type="text" class="col-md-10 form-control" id="country" placeholder="Country" [(ngModel)]="country"
              formControlName="country">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="country">Country Code: <span
                class="required-asterisk ">*</span></label>
            <input type="text" class="col-md-10 form-control" id="countryCode" placeholder="Country Code"
              [(ngModel)]="countryCode" formControlName="countryCode">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="type">Type: <span class="required-asterisk ">*</span></label>
            <input type="text" class="col-md-10 form-control" id="type" placeholder="Type" [(ngModel)]="type"
              formControlName="type">
          </div>
        </div>

        <br>
        <div class="row">
          <div class="form-group detail-input-group">

            <label class="col-md-2 col-form-label" for="live">Live: </label>
            <nb-toggle [(ngModel)]="live" formControlName="live"></nb-toggle>
          </div>
        </div>

        

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="barcode">Barcode: <span
                class="required-asterisk ">*</span></label>
            <input type="text" class="col-md-10 form-control" id="barcode" placeholder="Barcode" [(ngModel)]="barcode"
              formControlName="barcode">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="dateFormat">Date Format: <span
                class="required-asterisk ">*</span></label>
            <input type="text" class="col-md-10 form-control" id="dateFormat" placeholder="Date Format"
              [(ngModel)]="dateFormat" formControlName="dateFormat">
          </div>
        </div>


        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="alternateText">Alternate Text: </label>
            <input type="text" class="col-md-10 form-control" id="alternateText" placeholder="Alternate Text"
              [(ngModel)]="alternateText" formControlName="alternateText">
          </div>
        </div>

        <br>
        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="engineLanguage">Engine Language GCV: </label>
            <nb-toggle [(ngModel)]="engineLanguage" formControlName="engineLanguage"></nb-toggle>
          </div>
        </div>

        <br>
        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="countryEuropean">Is Country European: </label>
            <nb-toggle [(ngModel)]="countryEuropean" formControlName="countryEuropean"></nb-toggle>
          </div>
        </div>

        <br>

        <div class="form-group row">
          <div class="col-md-2 col-md-offset-10 input-group">
            <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
              [fullScreen]="true" size="medium">
              <p class="loading">Updating ....</p>
            </ngx-spinner>
            <button type="submit" class="btn btn-primary btn-lg" [disabled]="metadataGroup.invalid">Save</button>
          </div>
        </div>

      </form>


    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->