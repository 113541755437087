import { Component, OnInit } from "@angular/core";
import { NbMenuItem } from "@nebular/theme";
import { AuthService } from './auth/auth.service';
import {ProfileService} from './profile/profile.service';
 
@Component({
  selector: 'app-home-layout',
  template: `
  <nb-layout>
  <nb-layout-header>
  <div class="user-panel inline">
        <div class="image">
          <img [src]="profilePicture" class="img-circle elevation-2" id="userImage" alt="User Image">&nbsp;&nbsp;
          <span class="user-name">
          {{username}}
        </span>
        </div>
      </div>
  </nb-layout-header>

      <nb-sidebar>
      <nb-menu [items]="menu"></nb-menu>
      <div>
      <img [src]="loadTenantLogo()" class="responsive" id="companyLogo" alt="company logo">
      </div>
      </nb-sidebar>
      <nb-layout-column>
      <alert></alert>
      <router-outlet></router-outlet>
      </nb-layout-column>


      <nb-layout-footer class="d-none d-sm-inline-block">
      <div id="footerImg" class="d-none d-sm-inline-block">
      <p class="footer">Powered by <a href="idmerit.com" target="_blank"><img src="assets/img/IDM-logo.jpg" alt="" class="footer-image"/></a></p>
      </div>
  </nb-layout-footer>
 
</nb-layout>
  `
})
export class HomeLayoutComponent implements OnInit {

  menu: NbMenuItem[];

  username:String;
  profilePicture: String;
  enableUserMenu:boolean;
  enableDashboardMenu:boolean;
  helpMenu:boolean;
  verifyMenu:boolean;
  verificationMenu:boolean;
  metaDataMenu:boolean;
  applicationMenu:boolean;
  configurationMenu:boolean;
  reportMenu:boolean;
  scriptMenu:boolean;

  constructor(private authService:AuthService,
    private profileService: ProfileService){

  }

  ngOnInit() {
    this.username=this.authService.getUserName();
    const authUser = JSON.parse(localStorage.getItem('user'));
    const userType =authUser.userType;
    if(userType==='tenant_admin'){
      this.enableUserMenu = false;
      this.enableDashboardMenu = false;
      this.helpMenu = false;
      this.verificationMenu = false;
      this.metaDataMenu = true;
      this.applicationMenu = false;
      this.configurationMenu = false;
      this.verifyMenu = true;
      this.reportMenu = true;
      this.scriptMenu = true;
    } else if(userType==='super_admin'){
      this.enableUserMenu = true;
      this.enableDashboardMenu = true;
      this.helpMenu = true;
      this.verificationMenu = false;
      this.metaDataMenu = false;
      this.applicationMenu = false;
      this.configurationMenu = false;
      this.verifyMenu = true;
      this.reportMenu = true;
      this.scriptMenu = true;
    } else if(userType==='agent'){
      this.enableUserMenu = true;
      this.enableDashboardMenu = true;
      this.helpMenu = false;
      this.verificationMenu = true;
      this.metaDataMenu =  true;
      this.applicationMenu = true;
      this.configurationMenu = true;
      this.verifyMenu = false;
      this.reportMenu = true;
      this.scriptMenu = true;
    } else {
      this.enableUserMenu = true;
      this.enableDashboardMenu = false;
      this.helpMenu = false;
      this.verificationMenu = false;
      this.metaDataMenu = true;
      this.applicationMenu = true;
      this.configurationMenu = true;
      this.verifyMenu = true;
      this.reportMenu = true;
      this.scriptMenu = true;
    }
    this.menu = [
      {
        title: 'Dashboard',
        icon: 'activity',
        link: '/dashboard',
        home: true,
        hidden: this.enableDashboardMenu,
      },
      {
        title: 'Verifications',
        icon: 'checkmark-square-2',
        link: '/verifications',
        home: true,
        hidden:  this.verificationMenu,
      },
      {
        title: 'All Users',
        icon: 'people',
        link: '/user',
        home: true,
        hidden:this.enableUserMenu
      },
      {
        title: 'Meta Data',
        icon: 'code-outline',
        link: '/metadata',
        home: true,
        hidden: this.metaDataMenu,
      },
      {
        title: 'Applications',
        icon: 'browser-outline',
        link: '/application',
        home: true,
        hidden: this.applicationMenu,
      },
      {
        title: 'Video List',
        icon: 'video',
        link: '/verify',
        home: true,
        hidden: this.verifyMenu,
      },
      {
        title: 'Help',
        icon: 'question-mark-circle',
        link: '/help',
        home: true,
        hidden: this.helpMenu,
      },
      {
        title: 'Configuration',
        icon: 'browser-outline',
        link: '/configuration',
        home: true,
        hidden: this.configurationMenu,
      },
      {
        title: 'Report',
        icon: 'browser-outline',
        link: '/reports',
        home: true,
        hidden: this.reportMenu,
      },
      {
        title: 'Scripts',
        icon: 'browser-outline',
        link: '/scripts',
        home: true,
        hidden: this.scriptMenu,
      },
      {
        title: 'Profile',
        icon: 'person-outline',
        children: [
          {
            title: 'Change Password',
            icon: 'lock-outline',
            link: '/privacy',
          },
          {
            title: 'Logout',
            icon: 'unlock-outline',
          },
        ],
      }
    ];

    const user = this.profileService.getUserDetail();
    this.profilePicture = user.image || 'assets/img/user-pic.jpg';
  }

  loadTenantLogo(){
    return this.authService.getTenantLogo();
  }

  


}
