import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ConfigurationService } from '../configuration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../shared/_alert/alert.service';

@Component({
  selector: 'app-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss']
})
export class CheckboxButtonComponent implements ViewCell, OnInit {

  renderValue: string;
  disableCheckBox = false;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private configurationService: ConfigurationService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {

  }

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
    const value = !this.rowData.metadataLive;
    this.updateMetadata(this.rowData.id, value);
  }

  updateMetadata(id, metadataLive) {
    let payload =
    {
      id: id,
      metadataLive: metadataLive
    }

    this.configurationService.updateMetaData(payload).subscribe((result) => {
      this.alertService.success("Status change has update Sucessfully", this.options)
    }, (e) => {
      this.alertService.error(e.error.errorMessage, this.options)
    }).add(() => {
    });

  }

}
