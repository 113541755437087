<main class="main-wrapper">
    <div class="container">
        <!-- <button type="button" class="close btn " (click)="closePopup()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>  -->
        <div class="main-wrapper__inner"> 
            <div class="video-bar">
              <iframe [src]="videoURL" width="100%" height="570px" allow="camera;microphone" ></iframe> 
            </div>
            <div class="tab">
              <form role="search" class="navbar-form-custom form-group" [formGroup]='form'>

              <!-- <div role="search" class="navbar-form-custom form-group">  -->
                <div>
                    <div class="tab-menu form-check"> 
                        <button class="tab-menu-link" id="tab1" data-content="item-1" (click)="onNext('tab1')">
                            <span data-title="item-1" >Introduction</span> 
                        </button>
                        <button class="tab-menu-link" id="tab2" data-content="item-2" (click)="onNext('tab2')" *ngIf="!skipLiveness" >
                            <span data-title="item-2" >Face<br>Verification</span>
                        </button>
                        <button class="tab-menu-link" id="tab3" data-content="item-3" (click)="onNext('tab3')">
                            <span data-title="item-3">Document<br>Verification</span>
                        </button>
                        <button class="tab-menu-link" id="tab4" data-content="item-4" (click)="onNext('tab4')" *ngIf="mobile" >
                            <span data-title="item-4" >Social<br>Verification</span>
                        </button>
                        <button class="tab-menu-link" id="tab5" data-content="item-5" (click)="onNext('tab5')">
                            <span data-title="item-5" >Complete<br>Verification</span>
                        </button>
                    </div>
                    <div class="tab-bar">
                        <div class="tab-bar-content" [ngStyle]="{'display':displayTab1}" id="item-1">
                            <div class="texts">
                                <div class="heading-bar">Introduction</div>
                                <p>Good Morning / Afternoon / Evening</p>
                                <ul>
                                    <li>Hi and welcome to <b>{{tenantName}}'s </b> live video verification.</li>
                                    <li>My name is <b>{{agentName}}</b>, and I will guide you through the verification process.</li>
                                    <li>During this process, I'll ask a few questions to verify the personal information and the government-issued ID card strictly for verification purposes.</li>
                                    <li>Please note that this video will also be recorded for auditing purposes.</li>
                                    <li>Once again, thank you for your consent for your verification.</li>
                                    <li>Let's get started!</li>
                                </ul>
                                <div class="action-part right" *ngIf="!skipLiveness">
                                    <button type="button" (click)="onNext('tab2')" class="btn btn-primary default-btn-blue btn-lg paginate-button" >Next</button>
                                </div>
                                <div class="action-part right" *ngIf="skipLiveness"> 
                                    <button type="button" (click)="onNext('tab3')" class="btn btn-primary default-btn-blue btn-lg paginate-button" >Next</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-bar-content" [ngStyle]="{'display':displayTab2}" id="item-2" >
                            <div class="texts">
                                <div class="heading-bar">Face Verification</div>
                                <p><strong>Face Score: {{faceMatches +' %'}}</strong></p>
                                <p>Liveness <label [ngStyle]="getLivenessColor()">{{livenessDTO}}</label></p>
                                <div class="image-part">
                                    <!-- <img src="images/face-img.png"> -->
                                </div> 
    
                                <div class="col-lg-12 imageSlider" [hidden]="!showScannedImages">
                                <ng-image-slider  class="image-slider" #nav [images]="faceImageHistory" [infinite]="false" [autoSlide]="1" [imageSize]="{ width: 'auto', height: 'auto' }" slideImage="1"></ng-image-slider>
                                </div>

                                <div class="action-part right bottom"> 
                                    <button type="button" (click)="onNext('tab3')" class="btn btn-primary default-btn-blue btn-lg paginate-button">Next</button>
                                </div> 
                            </div>
                        </div>
                        <div class="tab-bar-content" [ngStyle]="{'display':displayTab3}" id="item-3">
                            <div class="texts">
                                <div class="heading-bar">Verify Document Details</div>
                                <div>
                                    <ul>
                                        <li><span class="label">Can you please confirm your first and last name?</span>
                                            <span class="value">{{name}}</span>
                                            <input type="checkbox" formControlName="isQue1" [(ngModel)]="isQue1">
                                        </li>
                                        <li><span class="label">Can you also tell me what type of ID you used for this verification?</span>
                                            <span class="value"> {{documentType}}</span>
                                            <input type="checkbox" formControlName="isQue2" [(ngModel)]="isQue2">
                                        </li>
                                        <li><span class="label">I see your DOB is listed.  Can you kindly confirm the year?</span>
                                            <span class="value"> {{dob}}</span>
                                            <input type="checkbox" formControlName="isQue3" [(ngModel)]="isQue3">
                                        </li>
                                        <li><span class="label">And finally, can you please provide the country where your ID is issued? </span>
                                            <span class="value">{{country}} {{countryCode}} </span>
                                            <input type="checkbox" formControlName="isQue4" [(ngModel)]="isQue4">
                                        </li>
                                        <!-- <li><span class="label">Is the Document Validated ? </span><span class="value">Expiry Date: {{countryCode}} </span>
                                            &nbsp; <input type="checkbox" [(ngModel)]="isQue4">
                                        </li> -->
                                    </ul>
                                </div>
    
                                <div class="col-lg-12 imageSlider" [hidden]="!showScannedImages">
                                    <ng-image-slider  class="image-slider" #nav [images]="IdImageHistory" [infinite]="false" [autoSlide]="1" [imageSize]="{ width: 'auto', height: 'auto' }" slideImage="1"></ng-image-slider>
                                </div> 

                                <div class="action-part right bottom" *ngIf="mobile">
                                    <button type="button" (click)="onNext('tab4')" class="btn btn-primary default-btn-blue btn-lg paginate-button">Next</button>
                                </div>
                                <div class="action-part right bottom" *ngIf="!mobile">
                                    <button type="button" (click)="onNext('tab5')" class="btn btn-primary default-btn-blue btn-lg paginate-button">Next</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-bar-content" [ngStyle]="{'display':displayTab4}" id="item-4" *ngIf="mobile">
                            <div class="texts">
                                <div class="heading-bar">Social Verification</div>
                                <div>
                                    <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box" >
                                    <p class="loading">Loading....</p>
                                    </ngx-spinner>
                                </div>    
                                <div class="verify-status">
                                <p>
                                    <button type="button" (click)="verifyByMobile()"  [disabled]="btnVerifyDisable  || !mobile" [ngClass]="{'gray' : btnVerifyDisable , 'green': !btnVerifyDisable}" class="default-btn  ">
                                        Verify ?
                                    </button> 
                                     <!-- &nbsp;<i class="fa fa-mobile fa-lg fa-fw" *ngIf="mobile"></i> {{mobile}}  -->
                                </p>
                                </div>

                                <div  class="col-lg-3" style="margin: 0 0 0 40%;" *ngIf="isShowInnerSpinner"> 
                                    <img src="{{innerspinner}}" alt="" />
                                </div>

                                <div class="col-lg-12" *ngIf="socialDetails">
                                    <div class="col-lg-12">
                                        <label><h4>Risk Score:</h4></label> <span>{{riskScore}}</span>
                                    </div>
                                    <div class="col-lg-12"></div>
                                    <br><br>
                                    <div class="col-lg-12">
                                        <div><label ><h4>Registered Profile </h4></label></div>  
                                    </div>
                                    <div class="col-lg-2" *ngFor="let data of registeredAccounts" style="margin: 1rem 1rem 1rem 1rem;">
                                        <img src="{{data.image}}" alt="" class="footer-image" style="width: 3rem;"/>
                                    </div>
                                </div>
                                 
                                <div class="action-part button">
                                    <button type="button" (click)="onNext('tab5')" class="btn btn-primary default-btn-blue btn-lg paginate-button button">Next</button>
                                </div> 
                            </div>
                        </div>
                        <div class="tab-bar-content" [ngStyle]="{'display':displayTab5}" id="item-5">
                            <div class="texts">
                                <div class="heading-bar">Complete the Verification process</div>
                                <ul>
                                    <li>Your verification is now complete.</li>
                                    <li>It will be approved once it is audited by the <b>{{tenantName}}</b> team.</li>
                                    <li>Thank you very much and have a wonderful day.</li>
                                    <li>Goodbye!</li>
                                </ul>
                                <div class="form">
                                    <label>Comments:<span class="required-asterisk">*</span>
                                        <div *ngIf="form.controls['agentComment'].touched && form.controls['agentComment'].invalid" class="alert-danger alert-custom">
                                            <div *ngIf="form.controls['agentComment'].errors && form.controls['agentComment'].errors.required">
                                                This field is required.
                                            </div>
                                        </div>
                                    </label>

                                    <textarea formControlName="agentComment" [(ngModel)]="agentComment" maxlength="200" ></textarea> 
                                </div>
                                <div class="action-part right">
                                    <button type="button" (click)="onSubmit('rejected')"  [disabled]="isSubmitted" class="default-btn" [ngClass]="{'gray' : isSubmitted, 'red': !isSubmitted}">Reject</button> 
                                    <!-- class="default-btn red">Reject</button> -->
                                    <button type="button" (click)="onSubmit('approved')" [disabled]="isSubmitted" class="default-btn" [ngClass]="{'gray' : isSubmitted, 'green': !isSubmitted}">Approve</button>
                                    <!-- class="default-btn green">Approve</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </form>
            </div>
        </div>
    </div>
  </main>
