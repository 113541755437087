import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MetaService } from '../meta.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../shared/_alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { countryJSON } from '../../../assets/files/country';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-metadata-edit',
  templateUrl: './metadata-edit.component.html',
  styleUrls: ['./metadata-edit.component.scss']
})
export class MetadataEditComponent implements OnInit {

  private selectedRow: any;

  countryList: any = countryJSON;
  pipe = new DatePipe('en-US');

  id: number;
  metadataGroup: FormGroup;
  country: String;
  countryCode: String;
  barcode: String;
  dateFormat: String;
  type: String;
  alternateText: String;
  live = false;
  previousLive = false;
  editMode = false;
  engineLanguage = false;
  countryEuropean= false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.metadataGroup = new FormGroup({
      country: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      live: new FormControl(''),
      barcode: new FormControl('', [Validators.required]),
      dateFormat: new FormControl('', [Validators.required]),
      alternateText: new FormControl(''),
      engineLanguage: new FormControl(''),
      countryEuropean: new FormControl(''),
      metadataExtensions: new FormControl([]),
    });


   
    this.route.params
      .subscribe((params: Params) => {
        this.id = params['id'];
        this.editMode = params['id'] != null;
        this.initForm(this.id); 
      });
  }

  onBack() {
    this.router.navigate(['metadata']);
  }

  private initForm(id) {

    if (this.editMode) {
      this.spinner.show();
      this.metaService.getEntries()
        .subscribe(entries => {
          const entry = entries.filter(function (entry) { 
            return entry.id == id;
          });
          console.log(entry[0]);
          this.country = entry[0].country;
          this.countryCode = entry[0].countryCode;
          this.type = entry[0].type;
          this.barcode = entry[0].barcode;
          this.dateFormat = entry[0].dateFormat;
          this.alternateText = entry[0].alternateText;
          this.live = entry[0].live;
          this.engineLanguage = entry[0].engineLanguage;
          this.countryEuropean = entry[0].countryEuropean; 
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
            console.log(error)
          });
    }
  }

  onSave() {
    this.spinner.show();
    if (!this.editMode) {
      const entry = {
        "country": this.metadataGroup.get('country').value,
        "countryCode": this.metadataGroup.get('countryCode').value,
        "type": this.metadataGroup.get('type').value,
        "dateFormat": this.metadataGroup.get('dateFormat').value,
        "barcode": this.metadataGroup.get('barcode').value,
        "alternateText": this.metadataGroup.get('alternateText').value,
        "live": this.metadataGroup.get('live').value,
        "engineLanguage": this.metadataGroup.get('engineLanguage').value,
        "countryEuropean": this.metadataGroup.get('countryEuropean').value
      };
      this.metaService.createMetaData(entry).subscribe((result) => {
        this.alertService.success("Metadata added sucessfully.", this.options)
        this.spinner.hide();
        this.onBack()
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });

    } else {
      const editEntry = {
        "id": this.id,
        "country": this.metadataGroup.get('country').value,
        "countryCode": this.metadataGroup.get('countryCode').value,
        "type": this.metadataGroup.get('type').value,
        "dateFormat": this.metadataGroup.get('dateFormat').value,
        "barcode": this.metadataGroup.get('barcode').value,
        "alternateText": this.metadataGroup.get('alternateText').value,
        "live": this.metadataGroup.get('live').value,
        "engineLanguage": this.metadataGroup.get('engineLanguage').value,
        "countryEuropean": this.metadataGroup.get('countryEuropean').value
      };
      this.metaService.editMeataData(editEntry).subscribe((result) => {
        this.alertService.success("Metadata has changed sucessfully.", this.options)
        this.spinner.hide();
        this.onBack()
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });
    }

  }

  confirm(rowData: any) {
    this.selectedRow = rowData;
    const modalRef = this.modalService.open(ConfirmationDialogComponent,{ size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = "Update Metadata Details";
    modalRef.componentInstance.message = "Are you sure you want to update the Metadata Details ?";
    modalRef.componentInstance.btnOkText = "Ok";
    modalRef.componentInstance.btnCancelText = "Cancel";

    modalRef.result.then((result) => {
      if (result) {
        this.onSave();
      } else {
        modalRef.close();
      }
    });
  }

}
